<template>
  <div>
    <Defaultlayout :userprofile="userprofile">
                <div
            class="modal fade repeat-alert-modal"
            id="UpdateProfilePic"
            tabindex="-1"
            role="dialog"
            aria-labelledby="UpdateProfilePic"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="UpdateProfilePic">
                      Upload Profile Pic
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" >
                  <div class="profile-btns d-flex flex-wrap justify-content-center">
                    <button type="button" class="btn hoverEffect" :style="colorObj" @click="$refs.vueShapeImg2.fileClick()">Upload File</button>
                    <button type="button" :style="colorObj" @click="getImg2" class="btn hoverEffect" >Crop</button>
                  </div>
                  <vueShapeImg @error="imgError" :useFrame="true" :timelyImageData="true" @imageDataChange="putImg2" ref="vueShapeImg2"></vueShapeImg>
                </div>
              </div>
            </div>
          </div>
      <section class="profile-section pt-0" v-if="loading==false">
        <div class="container signup-container">
          <div class="cover-photo profile-cover-photo">
            <img src="../../assets/images/profile-banner.jpg" alt="cover-photo" class="img-fluid">
          </div>
        </div>
        <div class="profile-photo text-center">
          <div class="photo">
            <img v-if="image" :src="image" width="150" height="150" alt="ptofile-photo" class="rounded-circle photoprfle">
            <img v-else src="../../assets/images/ptofile-photo.jpg" alt="ptofile-photo" class="rounded-circle">
          </div>
          <div class="profile-photo-changing photo-replace" :style="buttonColor">
            <img src="../../assets/images/svgs/photo-change.svg" alt="photo-change">
            <button data-toggle="modal" data-target="#UpdateProfilePic" class="hide_file" ref="file"></button>
          </div>
          <span class="help is-danger fileuplderr">Please upload only image file.</span>
        </div>
      </section>
      <section class="bottom-section" v-if="loading==false">
        <div class="container">
          <div
            class="alert alert-danger mb-5"
            role="alert"
            v-show="errormessage!='' && status==0"
          >{{errormessage}}</div>
          <div
            class="alert alert-success mb-5"
            role="alert"
            v-show="successmessage!='' && status==1"
          >{{successmessage}}</div>
          <form
            class="animated-form"
            method="post"
            @submit.prevent="signUp"
            enctype="multipart/form-data"
          >
            <div class="form-row">
              <div class="form-group col-md-12 active">
                <label :style="textcolor" for="InputName">
                  Name
                  <span class="helpmsg">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  id="name"
                  v-model="name"
                  v-validate="'required'"
                  :class="{error: errors.has('name')}"
                  maxlength="50"
                >
                <span v-show="errors.has('name')" class="help is-danger">{{$entrallfldrqrd}}</span>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6 active">
                <label :style="textcolor" for="inputNumber">
                  Mobile Number
                </label>
                <input
                  type="tel"
                  class="form-control"
                  id="phoneno"
                  name="phoneno"
                  v-model="phoneno"  
                  readonly
                >
              </div>
              <div class="form-group col-md-6 active">
                <label :style="textcolor" for="inputEmail4">
                  Email
                  <span class="helpmsg">*</span>
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  v-model="email"
                  v-validate="'required|email'"
                  :class="{error: errors.has('email')}"
                >
                <span v-show="errors.has('email')" class="help is-danger">{{$entrvalidemail}}</span>
              </div>
            </div>
           <div class="form-row" v-if="use_google_map == 1">
             <div class="col-md-12 form-group active">
             <GmapMap
                    :center="{lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)}"
                    @click="center=m.position"
                    :zoom="zoomProperty"
                    map-type-id="roadmap"
                    class="mapSignUp"
                    ref="addAddressMap"
                    >
                    <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center=m.position"
                     @dragend="updateCoordinates"                     
                    />
                    </GmapMap>
                    <form method="post" class="signupautocomplete">
                    <div class="position-relative">
                    <vue-google-autocomplete
                    ref="addressauto"
                    id="addressauto"
                    classname="form-control"
                    placeholder="Please type your address"
                    v-on:placechanged="getSignupAddressData"
                    @keypress.enter="$event.preventDefault()"
                    v-model="formatted_address"
                    types=""
                    :country="country_name"
                    >
                    </vue-google-autocomplete>
                    <div @click="getLocation()" class="location_icon">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 183.8 183.8" style="enable-background:new 0 0 183.8 183.8;" xml:space="preserve">
<g>
	<path d="M182.2,96.6c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0-0.1,0.1-0.1
		c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
		c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.8-0.1-1.5-0.4-2.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.6-0.7-1.1-1.2-1.5c-1.5-1.5-3.5-2.5-6-2.6c-0.1,0-0.2,0-0.3,0
		c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5,0c-2.8-0.1-5.7-0.2-8.5-0.1c-0.4,0-0.8,0-1.2,0c-1,0-2.1,0-3.1,0c-1.3-0.2-2.1-0.9-2.6-2.1
		c0-0.1,0-0.2-0.1-0.2c-1.4-8.3-4-15.9-7.8-22.9c-2-3.6-4.2-7-6.8-10.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.6-0.7-0.8-1
		c-3.3-3.8-7-7.2-11.2-10.2c-0.3-0.2-0.5-0.4-0.8-0.6c-7.5-5.4-16-9.1-25.2-11.2c-1.6-0.4-3.2-0.8-4.8-1.1c-0.4-0.3-0.7-0.6-1-1
		c-0.4-0.5-0.6-1.1-0.8-1.7c0-0.9,0-1.8,0-2.6c0.1-3.3,0.1-6.5,0-9.8c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
		C99.6,5.6,98.6,3.5,97,2C97,2,97,2,97,1.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.1,0-0.1-0.1-0.2-0.1C96,1.2,95.8,1.1,95.7,1c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c0,0-0.1,0-0.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0C92,0,92,0,92,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
		c0,0,0,0-0.1,0c-0.8,0-1.5,0.1-2.2,0.4c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1
		c-0.6,0.3-1.1,0.7-1.5,1.2c-1.5,1.5-2.5,3.5-2.6,6c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0,0.5c-0.1,2.8-0.2,5.7-0.1,8.5
		c0,0.4,0,0.8,0,1.2c0,1,0,2.1,0,3.1c-0.2,1.3-0.9,2.1-2.1,2.6c-0.1,0-0.2,0-0.2,0.1c-8.3,1.4-15.9,4-22.9,7.8
		c-3.6,2-7,4.2-10.1,6.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.3-0.7,0.6-1,0.8c-3.8,3.3-7.2,7-10.2,11.2c-0.2,0.3-0.4,0.5-0.6,0.8
		c-5.4,7.5-9.1,16-11.2,25.2c-0.4,1.6-0.8,3.2-1.1,4.8c-0.3,0.4-0.6,0.7-1,1c-0.5,0.4-1.1,0.6-1.7,0.8c-0.9,0-1.8,0-2.6,0
		c-3.3-0.1-6.5-0.1-9.8,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.6,1.2-6.1,2.7c0,0-0.1,0.1-0.1,0.1
		C1.9,86.9,1.8,87,1.8,87c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2C1.2,87.8,1.1,88,1,88.2
		c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2
		c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
		c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.8,0.1,1.5,0.4,2.2c0,0,0,0.1,0,0.1c0.1,0.2,0.1,0.4,0.2,0.6
		c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.3c0.3,0.6,0.7,1.1,1.2,1.5c1.5,1.5,3.5,2.5,6,2.6c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
		c0.2,0,0.3,0,0.5,0c2.8,0.1,5.7,0.2,8.5,0.1c0.4,0,0.8,0,1.2,0c1,0,2.1,0,3.1,0c1.3,0.2,2.1,0.9,2.6,2.1c0,0.1,0,0.2,0.1,0.2
		c1.4,8.3,4,15.9,7.8,22.9c2,3.6,4.2,7,6.8,10.1c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.3,0.6,0.7,0.8,1c3.3,3.8,7,7.2,11.2,10.2
		c0.3,0.2,0.5,0.4,0.8,0.6c7.5,5.4,16,9.1,25.2,11.2c1.6,0.4,3.2,0.8,4.8,1.1c0.4,0.3,0.7,0.6,1,1c0.4,0.5,0.6,1.1,0.8,1.7
		c0,0.9,0,1.8,0,2.6c-0.1,3.3-0.1,6.5,0,9.8c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0.1,2.5,1.2,4.6,2.7,6.1
		c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1
		c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1
		c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.8,0,1.5-0.1,2.2-0.4
		c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.1,0.6-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.2-0.1,0.3-0.1c0.6-0.3,1.1-0.7,1.5-1.2
		c1.5-1.5,2.5-3.5,2.6-6c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0.1-2.8,0.2-5.7,0.1-8.5c0-0.4,0-0.8,0-1.2
		c0-1,0-2.1,0-3.1c0.2-1.3,0.9-2.1,2.1-2.6c0.1,0,0.2,0,0.2-0.1c8.3-1.4,15.9-4,22.9-7.8c3.6-2,7-4.2,10.1-6.8
		c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.3,0.7-0.6,1-0.8c3.8-3.3,7.2-7,10.2-11.2c0.2-0.3,0.4-0.5,0.6-0.8c5.4-7.5,9.1-16,11.2-25.2
		c0.4-1.6,0.8-3.2,1.1-4.8c0.3-0.4,0.6-0.7,1-1c0.5-0.4,1.1-0.6,1.7-0.8c0.9,0,1.8,0,2.6,0c3.3,0.1,6.5,0.1,9.8,0c0.1,0,0.3,0,0.4,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c2.5-0.1,4.6-1.2,6.1-2.7c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
		C182.1,96.7,182.1,96.7,182.2,96.6z M118.1,137.4c-7.6,4.1-16.5,6.4-26.5,6.6c-0.6,0.1-1.3,0.1-2,0.1c-9.3-0.6-17.6-3-24.7-7.1
		c-7.9-4.7-14.2-11.4-18.5-18.9c-4.1-7.6-6.4-16.5-6.6-26.5c-0.1-0.6-0.1-1.3-0.1-2c0.6-9.3,3-17.6,7.1-24.7
		c4.7-7.9,11.4-14.2,18.9-18.5c7.6-4.1,16.5-6.4,26.5-6.6c0.6-0.1,1.3-0.1,2-0.1c9.3,0.6,17.6,3,24.7,7.1
		c7.9,4.7,14.2,11.4,18.5,18.9c4.1,7.6,6.4,16.5,6.6,26.5c0.1,0.6,0.1,1.3,0.1,2c-0.6,9.3-3,17.6-7.1,24.7
		C132.3,126.7,125.6,133,118.1,137.4z"/>
	<path d="M117.4,86.9c-1-5.4-3.8-10.2-7.7-13.8c-4.6-4.4-10.8-7.2-17.6-7.2c-1.8,0-3.6,0.2-5.3,0.5c-5.4,1-10.2,3.8-13.8,7.7
		c-4.4,4.6-7.2,10.8-7.2,17.6c0,1.8,0.2,3.6,0.5,5.3c1,5.4,3.8,10.2,7.7,13.8c4.6,4.4,10.8,7.2,17.6,7.2c1.8,0,3.6-0.2,5.3-0.5
		c5.4-1,10.2-3.8,13.8-7.7c4.4-4.6,7.2-10.8,7.2-17.6C117.9,90.4,117.7,88.6,117.4,86.9z M99.8,98.4c-0.5,0.6-1.1,1.1-1.7,1.6
		c-1.7,1.2-3.8,1.9-6.3,1.9c-2.6,0-4.8-0.8-6.5-2.1c-0.6-0.5-1.1-1.1-1.6-1.7c-1.2-1.7-1.9-3.8-1.9-6.3c0-2.5,0.8-4.8,2.1-6.5
		c0.5-0.6,1.1-1.1,1.7-1.6c1.7-1.2,3.8-1.9,6.3-1.9c2.6,0,4.8,0.8,6.5,2.1c0.6,0.5,1.1,1.1,1.6,1.7c1.2,1.7,1.9,3.8,1.9,6.3
		C101.9,94.5,101.2,96.7,99.8,98.4z"/>
</g>
</svg>
                    </div>
                    </div>
                    </form>
             </div>
            </div>
            <!-- <div class="form-row">
              <div class="form-group col-md-12 active">
                <label :style="textcolor" for="address2">
                  Street Address
                  <span class="helpmsg">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="address2"
                  name="address2"
                  v-model="address2"
                  v-validate="'required'"
                  :class="{error: errors.has('address')}"
                  maxlength="70"
                >
                <span v-show="errors.has('address')" class="help is-danger">{{$entrallfldrqrd}}</span>
              </div>
            </div> -->
            <div class="form-row">
              <div class="form-group col-md-12 active">
                <label :style="textcolor" for="address">
                  Apt Number
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  name="address"
                  v-model="address"
                  :class="{error: errors.has('address')}"
                  maxlength="70"
                >
                <span v-show="errors.has('address')" class="help is-danger">{{$entrallfldrqrd}}</span>
              </div>
            </div>
            <!-- <div class="form-row" style="display:none;">
              <div class="form-group col-md-6 active" v-if="country_name.length > 1">
                <div class="select-field">
                  <label :style="textcolor" for="country_id" class="control-label">
                    Select Country
                  </label>
                  <select
                    id="country_id"
                    name="country_id"
                    class="form-control"
                    value="Select Country"
                    v-model="country_id"
                    @change="getStates"
                  >
                    <option value>Select Country</option>
                    <option v-for="(country,index) in countries_name" :key="index" :value="country.id">
                    {{country.name}}  
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-6 select-field active">
                <label :style="textcolor" for="inputState">
                  Select State
                </label>
                <select
                  id="state_id"
                  name="state_id"
                  class="form-control"
                  value="Select State"
                  v-model="state_id"
                  @change="getCities"
                >
                  <option value>Select State</option>
                  <option
                    v-for="(state,ind) in states"
                    :value="state.id"
                    v-bind:key="ind"
                  >{{ state.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 select-field active">
                <label :style="textcolor" for="inputCity">
                  Select City
                </label>
                <select
                  id="city_id"
                  name="city_id"
                  class="form-control"
                  value="Select City"
                  v-model="city_id"
                  @change="getAreas"
                >
                  <option value>Select City</option>
                  <option
                    v-for="(jobTitle,ind) in cities"
                    :value="jobTitle.id"
                    :key="ind"
                  >{{ jobTitle.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 select-field active" v-show="country_id == 101">
                <label :style="textcolor" for="inputState">
                  Select Area
                </label>
                <select
                  id="area_id"
                  class="form-control"
                  value="Select Area"
                  name="area_id"
                  v-model="area_id"
                >
                  <option value>Select Area</option>
                  <option v-for="area in areas" :value="area.id" :key="area.id">{{ area.name }}</option>
                </select>
              </div>
            </div> -->
            <!-- <div class="form-row">
              <div class="form-group col-md-12 active">
                <label :style="textcolor" for="inputPincode">
                  {{pincode_label}}
                  <span class="helpmsg">*</span>
                </label>
                <input
                  type="text"
                  name="pincode"
                  class="form-control"
                  id="pincode"
                  v-model="pincode"
                  v-validate="'required|digits:'+pincode_length"
                  :class="{error: errors.has('pincode')}"
                  v-if="is_business_model != 3 && is_alpha_numeric == 0"
                  :maxlength="`${pincode_length}`"
                >
                <input
                  type="text"
                  name="pincode"
                  class="form-control"
                  id="pincode"
                  v-model="pincode"
                  v-validate="'required|alpha_num'"
                  :class="{error: errors.has('pincode')}"
                  v-if="is_business_model == 3 && is_alpha_numeric == 1"
                  :maxlength="`${pincode_length}`"
                >
                <input
                  type="text"
                  name="pincode"
                  class="form-control"
                  id="pincode"
                  v-model="pincode"
                  v-validate="'required|digits:'+pincode_length"
                  :class="{error: errors.has('pincode')}"
                  v-if="is_business_model == 3 && is_alpha_numeric == 0"
                  :maxlength="`${pincode_length}`"
                >
                <input
                  type="text"
                  name="pincode"
                  class="form-control"
                  id="pincode"
                  v-model="pincode"
                  v-validate="'required|alpha_num'"
                  :class="{error: errors.has('pincode')}"
                  v-if="is_business_model != 3 && is_alpha_numeric == 1"
                  :maxlength="`${pincode_length}`"
                >
                <span v-show="errors.has('pincode')" class="help is-danger">{{$entrallfldrqrd}}</span>
              </div>
            </div> -->

            <div class="form-row address-selector">
              <div class="form-group col-md-12 d-flex flex-wrap justify-content-center">
                <div
                  class="radio-dt"
                  v-for="(location,index) in location_types"
                  v-bind:key="index"
                >
                  <input
                    type="radio"
                    :id="location.val"
                    v-model="location_type"
                    :value="index"
                    @click="selectLocationType(location.id)"
                  >
                  <label :for="location.val">{{location.val}}</label>
                </div>
              </div>
            </div>
            <div class="form-row" v-if="other_location">
              <div class="form-group col-md-12 active">
                <label :style="textcolor" class="control-label" for="tag">Tag</label>
                <input type="text" name="tag" class="form-control" id="tag" v-model="tag">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 text-center mt-4">
                <button type="submit" :style="buttonColor" class="btn btn-primary mb-2 hoverEffect">Complete Registration</button>&nbsp;&nbsp;
              </div>
            </div>
          </form>
        </div>
      </section>

      <div class="container" v-if="loading==true">
        <p class="text-center">
          <img src="../../assets/images/loading.gif" />
        </p>
      </div>
    </Defaultlayout>
  </div>
</template>

<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import { bus } from "../../main";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import helper from '../../store/helper';
import localconfig from "../../global_settings.json"
import axios from "axios";
export default {
  name: "Signup",
  components: {
    Defaultlayout,
    'vue-google-autocomplete': VueGoogleAutocomplete,
  },
  mixins:[helper],
  data() {
    return {
      loading: false,
      formatted_address:"",
      country_filter_code:'',
      nw: "",
      image: "",
      phoneno: "",
      statebody: {
        country: ""
      },
      citybody: {
        state: ""
      },
      areabody: {
        city: ""
      },
      states: null,
      cities: null,
      areas: null,
      city_id: "",
      state_id: "",
      area_id: "",
      name: "",
      email: "",
      address: "",
      address2: "",
      zipcode1:"",
      street:"",
      country:"",
      state:"",
      city:"",
      pincode: "",
      passwrd: "",
      errormessage: "",
      successmessage: "",
      user_picture: "",
      status: "",
      options: [
        { value: "1", text: "aa" + " - " + "1" },
        { value: "2", text: "ab" + " - " + "2" },
        { value: "3", text: "bc" + " - " + "3" },
        { value: "4", text: "cd" + " - " + "4" },
        { value: "5", text: "de" + " - " + "5" }
      ],
      signupbody: {
        phone: "",
        name: "",
        email: "",
        user_picture: "",
        address: "",
        address2: "",
        pincode: "",
        street:"",
        passwrd: "",
        alias: "",
        alias_code: "",
        latitude:"",
        longitude:"",
        fcm_token:""
      },
      confirmpasswrd: "",
      passwordFieldType: "password",
      userprofile: null,
      confirmpasswordFieldType: "password",
      location_type: "0",
      other_location: false,
      tag: "",
      location_types: [
        { id: "0", val: "Home" },
        { id: "1", val: "Office" },
        { id: "2", val: "Other" }
      ],
      markers:[],
      center: { lat:"", lng:"" },
      latitude:"",
      longitude:"",
      updated_latitude:"",
      updated_longitude:"",
      country_name:"",
      imgSrc:'',
      country_code:"",
      countries_name:null,
      country_id:"",
      is_business_model:0,
      is_alpha_numeric:"",
      pincode_length:0,
      canvas2: null,
      canvas2Ctx: null,
      previousHeight : 0,
      previousWidth : 0,
      use_google_map:1,
      zoomProperty : 18,
      location_details:{
        lat:"",
        lng:""
      },
      customer_lat: '',
      customer_lng: ''
    };
  },
  mounted() {
    this.use_google_map = parseFloat(localStorage.getItem("use_google_map"));
    if (this.use_google_map == 1) {
      this.getLocation();
      bus.$on("customer_lat_lng", data => {
        this.customer_lat = data.lat
        this.customer_lng = data.long
        this.getLocation();
      });
    }    
    this.originalColor();
    //this.getCountries();
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/select2@4.1.0-beta.1/dist/js/select2.min.js"
    );
    document.head.appendChild(recaptchaScript);
    this.phoneno = localStorage.getItem("mobilenumber");
    this.name = localStorage.getItem("name")
    this.email = localStorage.getItem("email")
    this.address = localStorage.getItem("address")
    this.formatted_address = localStorage.getItem("address2")
    bus.$on("changeIt", data => {
      this.userprofile = data;
    });

    this.showGMap();
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.statebody.country = JSON.parse(data).country;
        this.country_filter_code = JSON.parse(data).country;
        this.is_business_model = JSON.parse(data).business_model;
        this.use_google_map = JSON.parse(data).use_google_map;
        this.is_alpha_numeric = JSON.parse(data).allow_alphanumeric_pincode;
        this.pincode_length = JSON.parse(data).pincode_length;
      } else {
        this.use_google_map = localconfig.use_google_map;
        this.statebody.country = localconfig.country;
        this.country_filter_code = localconfig.country;
        this.is_business_model = localconfig.business_model;
      }
      //this.getCountries();
    });
    window.$('<style>.address-selector .radio-dt input[type=radio]:checked ~ label { color: '+localStorage.getItem("css_button_customer_default_font")+'; }</style>').appendTo('body');
    window.$('<style>.address-selector .radio-dt input[type=radio]:checked ~ label { background-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('<style>.address-selector .radio-dt label:hover { background-color: '+localStorage.getItem("button_hover_color")+' }</style>').appendTo('body');
    window.$('<style>.address-selector .radio-dt label:hover { color: '+localStorage.getItem("css_button_customer_hover_font")+' }</style>').appendTo('body');

    this.country_name = localStorage.getItem("country_codes");
    this.country_name = this.country_name.split(",");
  },
  updated(){
		window.$(document).ready(function(){
			window.$('.location_icon svg path').css({fill: localStorage.getItem("button_default_color")})
		})
	},
  methods: {
    async getStreetAddressFrom(lat, long,formatedAddress) {
      var _this=this;
      this.country_id = '';
      this.state_id = '';
      this.city_id = '';
      this.area_id = '';
      //this.address = '';
      this.address2 = '';
      this.pincode = ''; 
            
			try {
				var {
					data
				} = await axios.get(
				"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
				lat +
				"%2C" +
				long +
				"&key="+((localStorage.getItem("google_map_key")!=null && localStorage.getItem("google_map_key")!="" && localStorage.getItem("google_map_key")!=undefined) ? localStorage.getItem("google_map_key") : 'AIzaSyDU1yuRqsrT2TFdn5srqr5F9iDzdDqzVL4')
				);
				if (data.error_message) {
					console.log(data.error_message)
				} else {
              if (formatedAddress){
                _this.formatted_address = data.results[0].formatted_address;
                window.$('.pac-target-input').val(data.results[0].formatted_address);
              }

              let returnData = {};
              const ADDRESS_COMPONENTS = {
                  subpremise : 'short_name',
                  street_number: 'short_name',
                  route: 'long_name',
                  locality: 'long_name',
                  administrative_area_level_1: 'long_name',
                  administrative_area_level_2: 'long_name',
                  country: 'long_name',
                  postal_code: 'short_name'
              };

              for (let i = 0; i < data.results[0].address_components.length; i++) {
                  let addressType = data.results[0].address_components[i].types[0];

                  if (ADDRESS_COMPONENTS[addressType]) {
                      let val = data.results[0].address_components[i][ADDRESS_COMPONENTS[addressType]];
                      returnData[addressType] = val;
                  }
              }

              this.address2 = data.results[0].formatted_address;
              this.zipcode1 = returnData.postal_code;
              this.street = returnData.street_number+" "+returnData.route;
              this.country = returnData.country;
              this.state = returnData.administrative_area_level_1;
              this.city = returnData.locality;

              //this.getCountries();
              // this.countries = this.country_filter_code;
              // this.$store.dispatch("getcountries", this.countries).then(response => {
              //     this.countries = JSON.parse(JSON.stringify(response.data));
              //     this.country_id = this.countries.filter(item=>{return item.name == returnData.country})[0].id;
              //     this.statebody.country = this.country_id;
              //     this.$store.dispatch("getstates", this.statebody).then(response => {
              //         this.states = JSON.parse(JSON.stringify(response.data));
              //         if(this.states.filter(item=>{return  item.name == returnData.administrative_area_level_1}) != undefined){
              //           this.state_id = this.states.filter(item=>{return  item.name == returnData.administrative_area_level_1})[0].id;
              //         }
              //         else
              //         {
              //           this.state_id = 0;
              //         }
              //         this.citybody.state = this.state_id;
              //         this.cities = null;
              //         this.$store.dispatch("getcities", this.citybody).then(response => {
              //             this.cities = JSON.parse(JSON.stringify(response.data));
              //             this.city_id = this.cities.filter(item=>{return item.name == returnData.locality})[0].id;
              //             //this.address = data.results[0].address_components[0].long_name;
              //             this.address2 = returnData.street_number + ' ' + returnData.route;
              //             this.pincode = returnData.postal_code;
              //             this.areabody.city = this.city_id;
              //             this.$store.dispatch("getarealist", this.areabody).then(response => {
              //               if (response.status == 1)
              //               {
              //                 this.areas = JSON.parse(JSON.stringify(response.data));
              //                 if (this.areas.filter(item=>{return item.name == data.results[0].address_components[1].long_name || item.name == data.results[0].address_components[2].long_name}).length > 0)
              //                    this.area_id = this.areas.filter(item=>{return item.name == data.results[0].address_components[1].long_name || item.name == data.results[0].address_components[2].long_name})[0].id;
              //                 else
              //                    this.area_id = '';
              //               }
              //             });
              //         })
              //     })
              // })

				}
			} catch (error) {
				console.log(error);
			}
		},
    putImg2 () {
     if (this.canvas2Ctx != null && this.canvas2Ctx != undefined)
        this.canvas2Ctx.clearRect(0, 0, 500, 500)
    },
    getImg2() {
      if(this.$refs.vueShapeImg2 != undefined)
      {
        this.imgSrc = this.$refs.vueShapeImg2.getImg('base64', 'image/jpeg', 0.7);
        this.$refs.vueShapeImg2.setImgSrc(this.imgSrc)
        this.image =this.imgSrc;
        window.$('#UpdateProfilePic').modal('hide')
      }
    },
    imgError (error) {
       console.error(error)
    },
    getSignupAddressData: function (addressData) {
      console.log(addressData);
      this.$refs.addAddressMap.$mapPromise.then((map) => {
        map.setZoom(18)
      })
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
			this.updated_latitude = addressData.latitude;
      this.updated_longitude = addressData.longitude;
      this.showGMap();
      window.$("#addressauto").val(addressData.street_number+" "+addressData.route+", "+addressData.locality+", "+addressData.administrative_area_level_1+" "+addressData.postal_code+", "+addressData.country)
      this.address2 = window.$("#addressauto").val();
      
      this.zipcode1 = addressData.postal_code;
      this.street = addressData.street_number+" "+addressData.route;
      this.country = addressData.country;
      this.state = addressData.administrative_area_level_1;
      this.city = addressData.locality;

      //this.getStreetAddressFrom(parseFloat(this.updated_latitude),parseFloat(this.updated_longitude),false)
		},
    updateCoordinates(location) {
      this.updated_latitude = location.latLng.lat();
      this.updated_longitude = location.latLng.lng();
      this.getStreetAddressFrom(parseFloat(this.updated_latitude),parseFloat(this.updated_longitude),true)
    },
    async getLocation() {
      var _this=this;
      if(!localStorage.getItem("address2")){
			try {
				await this.$getLocation({
					enableHighAccuracy: true
				}).then(coordinates => {
          _this.latitude = parseFloat(coordinates.lat);
          _this.longitude = parseFloat(coordinates.lng);
          _this.updated_latitude = parseFloat(coordinates.lat);
          _this.updated_longitude = parseFloat(coordinates.lng);
          _this.center.lat = parseFloat(coordinates.lat);
          _this.center.lng = parseFloat(coordinates.lng);
          _this.showGMap();
          this.getStreetAddressFrom(coordinates.lat,coordinates.lng,true)
				});

			} catch (error) {
        console.log(error)
        _this.latitude = parseFloat(localStorage.getItem("default_location_lat"));
        _this.longitude = parseFloat(localStorage.getItem("default_location_long"));
        _this.updated_latitude = parseFloat(localStorage.getItem("default_location_lat"));
        _this.updated_longitude = parseFloat(localStorage.getItem("default_location_long"));
        _this.center.lat = parseFloat(localStorage.getItem("default_location_lat"));
        _this.center.lng = parseFloat(localStorage.getItem("default_location_long"));
        _this.showGMap();
        this.getStreetAddressFrom(_this.latitude,_this.longitude,true)
			}
      } else {
        _this.latitude = this.customer_lat;
        _this.longitude = this.customer_lng;
        _this.updated_latitude = this.customer_lat;
        _this.updated_longitude = this.customer_lng;
        _this.center.lat = this.customer_lat;
        _this.center.lng = this.customer_lng;
        _this.showGMap();
        this.getStreetAddressFrom(_this.latitude,_this.longitude,true)
       

      }
		},    
    async showGMap() {
      this.showModal = true;
      const marker = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };
      this.markers = [];
      this.markers.push({ position: marker });
      this.center = marker;
    },
    getCountries(){
      this.countries = this.country_filter_code;
      this.countries_name = null;
      this.$store.dispatch("getcountries", this.countries).then(response => {
        if(response.status == 1){
          this.countries_name = JSON.parse(JSON.stringify(response.data));
        }
      });
    },
    getStates() {
      this.statebody.country = this.country_id;
      this.states = null;
      this.$store.dispatch("getstates", this.statebody).then(response => {
        if(response.status == 1){
          this.states = JSON.parse(JSON.stringify(response.data));
          this.state_id = ""
          this.city_id = ""
          this.area_id = ""
        }
      });
    },
    getCities(){
      this.citybody.state = this.state_id
      this.cities = null;
      this.$store.dispatch("getcities", this.citybody).then(response => {
        if(response.status == 1){
          this.cities = JSON.parse(JSON.stringify(response.data));
        }
      });
    },
    getAreas() {
      this.areabody.city = this.city_id;
      this.areas = null;
      this.$store.dispatch("getarealist", this.areabody).then(response => {
        if(response.status == 1) {
          this.areas = JSON.parse(JSON.stringify(response.data));
        }
      });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    confirmswitchVisibility() {
      this.confirmpasswordFieldType =
        this.confirmpasswordFieldType === "password" ? "text" : "password";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        files[0].type == "image/jpeg" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/png"
      ) {
        window.$(".fileuplderr").hide();
        this.createImage(files[0]);
        this.user_picture = files[0];
      } else {
        window.$(".fileuplderr").show();
      }
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    resetSignup() {
      window.location.href = "/";
    },
    ConvertFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    signUp() {
      if (!((this.zipcode1 != null && this.zipcode1.indexOf('undefined') <= -1) && (this.city != null && this.city.indexOf('undefined') <= -1) && (this.state != null && this.state.indexOf('undefined') <= -1) && (this.country != null && this.country.indexOf('undefined') <= -1) && (this.street != null && this.street.indexOf('undefined') <= -1) )){
        this.$toast.error("Select valid address with Street number Name, City, State and Country.")
        return false;
      }
      this.$validator.validate()
      this.$validator.validateAll().then(result => {
        if (result) {

          this.loading = true;
          this.signupbody.phone = this.phoneno;
          this.signupbody.name = this.name;
          this.signupbody.email = this.email;
          this.signupbody.address = this.address;
          this.signupbody.address2 = this.address2 ? this.address2 : this.formatted_address;
          this.signupbody.country = this.country;
          this.signupbody.state = this.state;
          this.signupbody.city = this.city;
          this.signupbody.zipcode1 = this.zipcode1;
          this.signupbody.street = this.street;
          this.signupbody.fcm_token = localStorage.getItem("fcm_token");
          // this.signupbody.country_id = this.statebody.country;
          // this.signupbody.state_id = this.state_id;
          // this.signupbody.city_id = this.city_id;
          // this.signupbody.area_id = this.area_id;
          // this.signupbody.pincode = this.pincode;
          if (this.imgSrc != null && this.imgSrc != undefined && this.imgSrc != '')
              this.signupbody.user_picture = this.ConvertFile(this.imgSrc,'data.png');
          this.signupbody.latitude = this.updated_latitude;
          this.signupbody.longitude = this.updated_longitude;
          if (this.location_type == "2" && this.tag != "") {
            this.signupbody.alias = this.tag;
          } else {
            this.signupbody.alias = this.location_types[this.location_type].val;
          }
          this.signupbody.alias_code = this.location_type;
          this.$store.dispatch("signup", this.signupbody).then(response => {
            this.loading = false;
            if (JSON.parse(JSON.stringify(response)).status == "1") {
              bus.$emit("changeIt", localStorage.getItem("user"));
              localStorage.setItem("is_login", true);
              localStorage.setItem("first_time_signup", true);
              this.location_details.address = this.address2;
              this.location_details.lat = this.updated_latitude;
              this.location_details.lng = this.updated_longitude;
              //localStorage.setItem("location_details", JSON.stringify(this.location_details));
              window.location.href = "/";
            } else {
              this.errormessage = response.message;
              this.status = response.status;
            }
          });
        }
        else{
          this.$toast.error("Enter the required information");
        }
      });
    },
    selectLocationType(location_id) {
      if (location_id == 2) {
        this.other_location = true;
      } else {
        this.other_location = false;
      }
    },
  }
};
</script>
<style>
@import "https://cdn.jsdelivr.net/npm/select2@4.1.0-beta.1/dist/css/select2.min.css";
</style>
<style>
.hide_file {
  background-color: #24c7fa;
  background-image: url("../../assets/images/camara.png");
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
</style>